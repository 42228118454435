<button smTooltip="{{tooltipText}}"
        [matTooltipPosition]="tooltipPosition"
        type="button"
        ngxClipboard
        smClickStopPropagation
        class="btn btn-primary"
        [class]="theme"
        [class.hideBackground]="hideBackground"
        [class.inline]="inline"
        [cbContent]="clipboardText"
        [disabled]="disabled"
        (cbOnSuccess)="copyToClipboard()"
>
  <i class="al-icon sm-md" [class]="copied ? 'al-ico-success' : copyIcon || 'al-ico-copy-to-clipboard'" [class.mr-2]="label"></i>
  <span>{{label}}</span>
</button>
