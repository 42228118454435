<div *ngIf="data || editMode; else noData" class="md-editor-preview" [class.editor]="editMode">
  <div class="md-content" [class.edit-mode]="editMode" [class.dirty]="isDirty" [class.editor-hidden]="editorVisible">
    <div class="actions">
      <div class="actions-buttons ">
        <button *ngIf="!editMode && editModeChanged.observed"
                class="btn btn-cml-primary btn-icon btn-expand-view  md-button mr-2"
                [smTooltip]="isExpand? 'Minimize view' : 'Expand view'"
                matTooltipPosition="below"
                (click)="expandClicked()">
          <i class="al-icon sm-md" [ngClass]="isExpand ? 'al-ico-hor-minimize' : 'al-ico-hor-expand'"></i>
        </button>
        <button *ngIf="!readOnly && !editMode" class="btn btn-cml-primary md-button" (click)="editClicked()">EDIT
        </button>
      </div>
    </div>
    <button *ngIf="editMode" class="btn btn-cml-primary btn-preview-toggle" (click)="togglePreview()">
      {{ editorVisible ? 'EDIT' : 'PREVIEW' }}
    </button>
    <span *ngIf="editMode && !editorVisible" class="preview-header">PREVIEW</span>
    <md-editor #editorComponent
               name="Content"
               [(ngModel)]="data"
               height="100%"
               [mode]="editMode ? 'editor' : 'preview'"
               [options]="options"
               [upload]="handleUpload"
               (onEditorLoaded)="editorReady($event)"
               (onPreviewDomChanged)="domFixes()"
               (ngModelChange)="checkDirty()"
    >
      <div *ngIf="handleUpload" custom-btns class="extra-buttons">
        <input #imgInput type="file" accept=".jpg,.jpeg,.png,.webm" multiple="multiple" style="display: none" (change)="uploadImg($event)" />
        <mat-menu
          #imageActions=matMenu
          smClickStopPropagation
          class="action-menu dark"
        >
          <button mat-menu-item class="action" (click)="imgInput.click()">
            <i class="al-icon al-ico-upload sm-md pointer"></i>Upload Image
          </button>
          <hr class="dark"/>
          <ng-container *ngIf="resources?.length > 0; else: noResources">
            <button
              *ngFor="let resource of resources; trackBy: trackByUrl"
              mat-menu-item class="action" (click)="insertImage(resource.url)">
                <i class="al-icon sm-md" [class]="resource.unused ? 'al-ico-broken-file' : 'al-ico-image'"></i>
                <span class="ellipsis">{{resource.url | baseName}}</span>
                <i class="al-icon al-ico-trash sm-md" (click)="$event.stopPropagation(); $event.preventDefault(); deleteResource.emit(resource.url)"></i>
            </button>
          </ng-container>
          <ng-template #noResources><div class="action src-empty">No uploads yet</div></ng-template>
        </mat-menu>

        <button
          [matMenuTriggerFor]="imageActions"
          (onMenuOpen)="imageMenuOpened.emit(data)"
          type="button"
          class="btn btn-default btn-img-menu"
          smTooltip="Image resources"
        ><i class="al-icon al-ico-image sm-md"></i><i class="al-icon al-ico-dropdown-arrow sm"></i></button>
      </div>
    </md-editor>
  </div>

  <div class="md-footer">
    <i class="al-icon al-ico-markdown" (click)="openMDCCheatSheet()" smTooltip="Markdown cheat sheet"></i>
    <div [class]="(editMode && isDirty ? 'active' : '') + ' md-warning'">
      <span *ngIf="duplicateNames">* You have duplicate names on different iframes</span>
      <span *ngIf="!duplicateNames">* You have unsaved changes</span>
    </div>
    <div *ngIf="editMode" class="right-buttons">
      <button class="btn btn-cml-primary btn-outline"
              (click)="cancelClicked()">CANCEL
      </button>
      <button class="btn btn-cml-primary"
              [disabled]="!isDirty || duplicateNames"
              (click)="save()">SAVE
      </button>
    </div>
  </div>

</div>
<ng-template #noData>
  <ng-content select="[no-data]"></ng-content>
</ng-template>
