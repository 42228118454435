<header>
  <div class="project ellipsis">{{experiment.project?.name}}</div>
  <div class="d-flex align-items-center">
    <div [smTooltip]="experiment.name"  class="experiment-name al-header"><a smEllipsisMiddleDirective [routerLink]="buildUrl()">{{experiment.name}}</a>
    </div>
    <sm-id-badge class="ml-3" [id]="experiment.id" (copied)="copyToClipboard()"></sm-id-badge>
  </div>
</header>
<sm-tag-list [tags]="tags"></sm-tag-list>
<div class="general-info">
  <i [class]="'mr-1 icon sm bw i-'+ experiment.status"></i>
  <span>{{experimentsStatusLabels[experiment.status]}}</span>
  <span class="dot"></span>
  <span class="flex-shrink-0">{{experiment.last_iteration | number | NA}} iterations</span>
  <span class="dot"></span>
  <span class="flex-shrink-1" [smTooltip]="(experiment.last_update | date : timeFormatString) || ''">Last updated {{experiment.last_update | timeAgo | NA}}</span>
</div>
