<sm-confirm-dialog [displayX]="false">
  <iframe #box class="box" [srcdoc]="text | safe: 'html'"></iframe>
  <div class="footer-buttons">
      <div class="cbx">
        <mat-checkbox
        #checkboxInput="ngModel"
        [(ngModel)]="isApprove"
        name="isApprove"
        class="mr-3"
        >I have read and accept ClearML's Terms Of Use
        </mat-checkbox>
      </div>
      <div class="d-flex justify-content-center">
        <button (click)="closeDialog()" class="btn btn-neon"
                [style.background-color]="isApprove ? '' : '#E7E7E8'"
                [disabled]="!isApprove">
          ACCEPT
        </button>
      </div>
  </div>
</sm-confirm-dialog>
