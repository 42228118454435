<div class="h-100 d-flex flex-column step" [ngClass]="{'hide-step' : hideStep}">
  <div [class]="'mx-auto step-icon ' + icon" [ngClass]="{'hide-step' : hideStep && enableIconAnimation}"></div>
  <div class="text-center label">{{label}}</div>
  <div class="text-center sub-label">{{subLabel}}</div>
  <div class="text-center step-number" [ngClass]="{'hide-step' : hideStep}">{{stepNumber}}</div>
  <div cdkScrollable class="choose-box" [ngClass]="{'hide-step' : hideStep}">
    <div class="choose-section">
      <ng-content select="[choose-section]"></ng-content>
    </div>
    <div class="buttons-footer" *ngIf="showFooter">
      <div class="w-100">
        <div class="divider"></div>
      </div>
      <div class="w-100 buttons-footer-content" *ngIf="showFooter">
      <ng-content select="[buttons-footer]"></ng-content>
      </div>
    </div>
  </div>
</div>
