<div class="d-flex align-items-center">
  <div class="auto-refresh p-2">
    <mat-slide-toggle
      (change)="onAutoRefreshChange($event)"
      [disabled]="!allowAutorefresh"
      [checked]="(allowAutorefresh === false) ? false : autoRefreshValue">Auto Refresh
    </mat-slide-toggle>
  </div>
  <div class="p2">
    <button class="btn btn-secondary" smTooltip="Refresh" [disabled]="disabled"
            (click)="onRefreshLogClicked.emit({isAutoRefresh: false})">
      <i class="fas fa-sync"></i>
    </button>
    <button *ngIf="showSettings" class="btn" smTooltip="Settings" (click)="toggleSettings.emit()">
      <i class="fas fa-cog"></i>
    </button>
  </div>
</div>
