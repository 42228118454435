<sm-menu
  [iconClass]="'al-icon al-ico-settings al-color pointer create-new-icon ' + (disabled ? 'pointer-events-none blue-600' : 'blue-300')"
  smMenuClass="light-theme custom-columns"
  data-id="CustomizeColumn"
  buttonTooltip="Customize table"
  [showButton]="false"
  (click)="!disabled && getMetricsToDisplay.emit()"
  (menuClosed)="setMode(CustomColumnMode.Standard)"
  [style.pointer-events]="disabled ? 'none' : 'initial'"
>
  <div *ngIf="!customColumnMode" (click)="$event.stopPropagation()">
    <sm-custom-columns-list
      [tableCols]="tableCols"
      [isLoading]="isLoading"
      (removeColFromList)="removeColFromList.emit($event)"
      (selectedTableColsChanged)="selectedTableColsChanged.emit($event)"
    >
    </sm-custom-columns-list>

    <div [ngClass]="{loading: isLoading, loaded: !isLoading}">
      <div class="sm-menu-header">ADD CUSTOM COLUMN</div>
      <div class="custom-column-buttons">
        <div class="add-button metrics-button"
             smClickStopPropagation
             [ngClass]="{disabled: !metricVariants.length}"
             (click)="$event.stopPropagation(); metricVariants.length && setMode(CustomColumnMode.Metrics)"
        ><i class="al-icon al-ico-add sm mr-1"></i><span data-id="Metric Button" class="caption">METRIC</span>
        </div>
        <div class="add-button metrics-button"
             smClickStopPropagation
             [ngClass]="{disabled: !hasHyperParams}"
             (click)="$event.stopPropagation(); hasHyperParams && setMode(CustomColumnMode.HyperParams)"
        ><i class="al-icon al-ico-add sm mr-1"></i><span data-id="Hyperparameters Button" class="caption">HYPERPARAMETERS</span>
        </div>
      </div>
    </div>
  </div>
  <sm-select-metric-for-custom-col *ngIf="customColumnMode === CustomColumnMode.Metrics"
                                   [tableCols]="tableCols"
                                   [metricVariants]="metricVariants"
                                   (goBack)="setMode(CustomColumnMode.Standard)"
                                   (selectedMetricToShow)="selectedMetricToShow.emit($event)">
  </sm-select-metric-for-custom-col>
  <sm-select-hyper-params-for-custom-col *ngIf="customColumnMode === CustomColumnMode.HyperParams"
                                         class="hyper-params-custom-col"
                                         [tableCols]="tableCols"
                                         [hyperParams]="hyperParams"
                                         (goBack)="setMode(CustomColumnMode.Standard)"
                                         (selectedHyperParamToShow)="selectedHyperParamToShow.emit($event)"
                                         (clearSelection)="clearSelection.emit()">
  </sm-select-hyper-params-for-custom-col>
</sm-menu>
