<div mat-button class='cell pointer'>
  <i [class]="'al-icon ' + ((showOnlyUserWork$ | async)||  ((tagsFilters$ | async)?.length > 0)? 'al-ico-filter-on':'al-ico-filter-off')" [matMenuTriggerFor]="menu"><span class="path1"></span><span class="path2"></span></i>
</div>
<mat-menu #menu="matMenu" classList="light-theme" (close)="closeMenu()">
  <button mat-menu-item
          smClickStopPropagation
          class="user-filter-button"
          (click)="switchUserFocus()">
    <i [class.al-ico-success]="showOnlyUserWork$ | async" class="al-icon sm-md"></i>
    <div class="mr-3">My Work</div>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="subMenu" class="d-flex align-items-center">
    <i [class.hidden]="!((tagsFilters$ | async)?.length > 0)" class="filter-point al-icon sm-md al-ico-success"></i>
    <span>Tags</span>
  </button>
  <mat-menu #subMenu="matMenu" class="table-card-filter-menu light-theme" (close)="closeMenu()">
    <form class="filter-form">
      <mat-form-field appearance="fill"  smClickStopPropagation class="tags-menu-input w-100">
        <input matInput (input)="setSearchTerm($event)" name="filter" [(ngModel)]="searchTerm" autocomplete="off">
        <i matSuffix [class]="'fa mr2 ' + (searchTerm? 'fa-times pointer':'fa-search')" (click)="searchTerm && clearSearch()"></i>
      </mat-form-field>
    </form>
    <div class="filter-type" smClickStopPropagation>
          <span class="link mr-3" [class.selected]="matchMode !== 'AND'"
                (click)="toggleMatch()">Any</span>
      <span class="link" [class.selected]="matchMode === 'AND'"
            (click)="toggleMatch()">All</span>
    </div>
    <div class="results scroll"  smClickStopPropagation>
      <sm-checkbox-three-state-list
        [supportExcludeFilter]="true"
        [checkedList]="tagsFilters$ | async"
        [options]="tagsLabelValue | filter : searchTerm: 'label'"
        (filterChanged)="emitFilterChangedCheckBox( $event)"
      > </sm-checkbox-three-state-list>
    </div>
    <div *ngIf="tagsLabelValue?.length>0" class="column-explanation">Click to include tag. Click again to exclude.</div>
  </mat-menu>
  <hr/>
  <button mat-menu-item
          smClickStopPropagation
          class="user-filter-button"
          (click)="clearAll()">
    <div class="lbl">Clear all</div>
  </button>
</mat-menu>
